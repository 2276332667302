import { mapGetters, mapState } from 'vuex'
import { dangerToast } from '../helpers/toasts'
import UserEvent from '../objects/UserEvent'

export default {
    computed: {
        ...mapGetters({
            isLoggedIn: 'user/isLoggedIn',
            hasSubscription: 'subscription/hasSubscription',
            hasPlans: 'subscription/hasPlans',
            publishing: 'document/onpublishing',
        }),
        ...mapState({
            user: 'user',
            subscription: 'subscription',
            document: 'document',
        }),
        current_plan() {
            return this.getPlan(this.subscription.one_click_content.plan_category) ?? {}
        },
        sisterSite() {
            return !this.subscription.has_other_site_subscription
                ? ''
                : this.subscription.has_other_site_subscription == 'CH'
                  ? `<a href='${this.sisterSiteUrl}' target='_blank'>My Crossword Maker</a>`
                  : `<a href='${this.sisterSiteUrl}'>My Word Search</a>`
        },
        sisterSiteUrl() {
            return !this.subscription.has_other_site_subscription
                ? ''
                : this.subscription.has_other_site_subscription == 'CH'
                  ? window.crossword_url
                  : window.wordsearch_url
        },
    },
    data() {
        return {
            frequency_change_success: false,
            subscription_status_loading: false,
            one_click_upgrade: {
                plan: null,
                subscriptionCycle: 'yearly',
                success_feedback:
                    'Your plan has been updated and will begin automatically when your current subscription ends. You can continue to use all features, but won’t be charged until your next scheduled billing date.',
                renewal_msg: '',
            },
        }
    },
    methods: {
        getPriceDifferential(plan) {
            const new_plan = this.getPlan(plan)

            if (Object.keys(this.current_plan).length === 0)
                return `$${this.subscriptionAmount(new_plan.amount_in_cents / 100)}`

            if (new_plan?.name?.toLowerCase() === this.current_plan?.name?.toLowerCase())
                return `$${this.subscriptionAmount(new_plan.amount_in_cents / 100)}`

            const diff = this.current_plan.amount_in_cents - new_plan.amount_in_cents

            return diff > 0
                ? `$${this.subscriptionAmount(Math.abs(diff) / 100)} less`
                : `$${this.subscriptionAmount(Math.abs(diff) / 100)} extra`
        },
        getPlan(plan) {
            if (!plan) return null

            if (this.subscription_status_loading) return {}

            let brand = this.brand
            if (this.brand?.toLowerCase() == 'flashcard') brand = 'flashcards'
            if (this.brand?.toLowerCase() == 'account') brand = 'worksheet'

            if (['basic', 'standard'].includes(plan.toLowerCase()))
                return this.subscription.plans[plan][brand][this.subscription.one_click_content.plan_frequency]
            if (plan.toLowerCase() == 'premium')
                return this.subscription.plans[plan][this.subscription.one_click_content.plan_frequency]

            return this.subscription.plans[plan]
        },
        setUpgradeOption(option) {
            if (this.subscription_status_loading) return {}

            const plan = this.getPlan(option.type)
            return {
                ...option,
                ...{
                    description: option.description
                        .replaceAll('[price]', this.getPriceDifferential(option.type))
                        .replaceAll(
                            '[frequency]',
                            this.subscription.one_click_content.plan_frequency == 'yearly' ? 'year' : 'month',
                        ),
                },
                id: plan.id,
            }
        },
        setPlanDetails(description) {
            if (this.subscription_status_loading) return ''

            if (Object.keys(this.current_plan).length === 0) return description.replaceAll('[site_name]', this.sisterSite)

            return description
                .replaceAll('[price]', `$${this.subscriptionAmount(this.current_plan.amount_in_cents / 100)}`)
                .replaceAll('[frequency]', this.subscription.one_click_content.plan_frequency == 'yearly' ? 'year' : 'month')
                .replaceAll('[date]', this.subscription.current_period_end)
                .replaceAll('[site_name]', this.sisterSite)
        },
        swapPlan(payload) {
            this.subscription_status_loading = true
            this.$store
                .dispatch('subscription/changeFrequency', payload)
                .then((response) => {
                    this.one_click_upgrade.success_feedback = this.setPlanDetails(payload.desc)
                    this.$store.dispatch('subscription/setOneClickUpgrade', this.one_click_upgrade)
                    this.$store.dispatch('user/getPayments').then(() => {
                        this.showSuccess()
                    })
                    this.subscription_status_loading = false
                })
                .catch((err) => {
                    this.subscription_status_loading = false
                    if (!this.document.is_published) return
                    this.$bvToast.toast('There was an error updating your subscription.', dangerToast)
                })
        },
        async handleExploreMoreOptionsClick() {
            await this.resetOneClickUpgrade()
            await this.$logTrackingEvent(UserEvent.EXPLORE_MORE_OPTIONS_CLICKED)
            await this.goPremium()
        },
        async changePlan(payload) {
            this.one_click_upgrade.plan = this.getPlan(payload.plan)
            this.one_click_upgrade.subscriptionCycle = this.subscription.one_click_content.plan_frequency

            if (payload.plan == 'lifetime') this.one_click_upgrade.subscriptionCycle = payload.plan

            await this.$store.dispatch('subscription/setOneClickUpgrade', this.one_click_upgrade).then(async () => {
                if (this.hasSubscription && payload.plan != 'lifetime') {
                    return this.swapPlan({
                        plan: this.one_click_upgrade.plan.id,
                        downgrade: payload.downgrade,
                        desc: payload.desc,
                    })
                }

                await this.goPremium()
            })
        },
        showSuccess(action = 'change-plan') {
            if (action === 'change-plan') {
                if (this.publishing) this.$bvModal.hide('printable-modal')
                this.$bvModal.hide('paywall-modal')
            }
            this.$bvModal.show('success-modal')
        },
        async resetOneClickUpgrade() {
            this.one_click_upgrade = {
                plan: null,
                subscriptionCycle: this.subscription.one_click_content.plan_frequency,
                success_feedback:
                    'Your plan has been updated and will begin automatically when your current subscription ends. You can continue to use all features, but won’t be charged until your next scheduled billing date.',
                renewal_msg: '',
            }

            await this.$store.dispatch('subscription/setOneClickUpgrade', this.one_click_upgrade)
        },
        purchase() {
            this.one_click_upgrade.plan = this.getPlan('single')
            this.one_click_upgrade.subscriptionCycle = this.subscription.one_click_content.plan_frequency
            this.$store.dispatch('subscription/setOneClickUpgrade', this.one_click_upgrade).then(() => {
                this.showCheckoutModal(true)
                this.$bvModal.hide('printable-modal')
            })
        },
        async resumePlan() {
            await this.$store.dispatch('subscription/resumeSubscription')
        },
        async cancelSubscription() {
            try {
                const cancellationReason = this.subscription.cancellation_reason ?? ''
                this.$bvModal.hide('cancel-subscription')
                await this.$store.dispatch('subscription/cancelSubscription')
                this.showSuccess('cancel-plan')
                await this.$logTrackingEvent(UserEvent.SUBSCRIPTION_CANCELLED, cancellationReason)
            } catch (error) {
                console.error(error)
                this.$bvToast.toast(`We were unable to cancel your subscription`, dangerToast)
            }
        },
    },
}
